<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-10 ml-auto mr-auto">
        <div class="card card-calendar">
          <div class="card-body">
            <div id="fullCalendar" ref="calendar"></div>
          </div>
        </div>
      </div>
    </div>
    <modal
      :show.sync="modals.add"
      headerClasses="justify-content-center"
      footer-classes="justify-content-center"
      >
      <h5 slot="header" class="modal-title">Manage Event</h5>
      <form>
        <div class="row">

          <div class="form-group col-12">
            <label for>Title</label>
            <fg-input
              name="title"
              v-model="model.title"
              v-validate="modelValidations.title"
              :error="getError('title')"
            ></fg-input>
          </div>

          <div class="form-group col-12">
            <label for>Description</label>
            <fg-input
              name="desc"
              v-model="model.desc"
              v-validate="modelValidations.desc"
              :error="getError('desc')"
            ></fg-input>
          </div>

          <div class="form-group col-12">
            <label for>Start Datetime</label>
            <fg-input
              name="start_datetime"
              v-model="model.start_datetime"
              v-validate="modelValidations.start_datetime"
              :error="getError('start_datetime')"
            >
              <!-- <el-date-picker v-model="model.date" type="date" placeholder="Pick a day" :picker-options="datePickerOptions"></el-date-picker> -->
              <el-date-picker v-model="model.start_datetime" type="datetime" placeholder="Select start date and time"></el-date-picker>
            </fg-input>
          </div>

          <div class="form-group col-12">
            <label for>End Datetime</label>
            <fg-input
              name="end_datetime"
              v-model="model.end_datetime"
              v-validate="modelValidations.end_datetime"
              :error="getError('end_datetime')"
            >
              <!-- <el-date-picker v-model="model.date" type="date" placeholder="Pick a day" :picker-options="datePickerOptions"></el-date-picker> -->
              <el-date-picker v-model="model.end_datetime" type="datetime" placeholder="Select end date and time"></el-date-picker>
            </fg-input>
          </div>

          <div class="form-group col-12">
            <label for>Employee</label>
            <fg-input
              name="user_id"
              v-model="model.user_id"
              v-validate="modelValidations.user_id"
              :error="getError('user_id')"
            >
              <el-select
                name="user_id"
                :class="!getError('user_id') ? 'select-default' : 'select-danger'"
                class="select-default"
                placeholder="Select Employee"
                v-model="model.user_id"
                filterable
              >
                <el-option
                  v-for="(option, index) in employees"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.employee_id} - ${option.name}`"
                  :key="index"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
      </form>
      <template slot="footer">
        <p-button type="success" @click="validate()">Save</p-button>
      </template>
    </modal>

    <modal
      :show.sync="modals.view"
      headerClasses="justify-content-center"
      footer-classes="justify-content-center"
      >
      <h5 slot="header" class="modal-title">View Appointment/Event</h5>
      <div class="row" v-if="event">
        <div class="col-lg-12">
          <table class="table table-bordered table-stripped table-hover table-condensed" style="width: 100%;">
            <tr v-if="event.appointment_id">
              <th class="text-left">ID</th>
              <td class="text-right">{{ event.appointment_id }}</td>
            </tr>
            <tr v-if="event.patient">
              <th class="text-left"  rowspan="3">Patient</th>
              <td class="text-right">{{ event.patient.patient_id }}</td>
            </tr>
            <tr v-if="event.appointment_id">
              <td class="text-right">{{ event.patient.name }}</td>
            </tr>
            <tr v-if="event.appointment_id">
              <td class="text-right">{{ event.patient.phone }}</td>
            </tr>
            <tr v-if="event.doctor">
              <th class="text-left"  rowspan="3">Doctor</th>
              <td class="text-right">{{ event.doctor.employee_id }}</td>
            </tr>
            <tr v-if="event.appointment_id">
              <td class="text-right">{{ event.doctor.name }}</td>
            </tr>
            <tr v-if="event.appointment_id">
              <td class="text-right">{{ event.doctor.phone }}</td>
            </tr>
            <tr v-if="event.location">
              <th class="text-left"  rowspan="3">Location</th>
              <td class="text-right">{{ event.location.location_id }}</td>
            </tr>
            <tr v-if="event.appointment_id">
              <td class="text-right">{{ event.location.name }}</td>
            </tr>
            <tr v-if="event.appointment_id">
              <td class="text-right">{{ event.location.phone }}</td>
            </tr>
            <tr v-if="event.appointment_type">
              <th class="text-left">Appointment Type</th>
              <td class="text-right">{{ event.appointment_type }}</td>
            </tr>
            <tr v-if="event.datetime">
              <th class="text-left">Date & Time</th>
              <td class="text-right">{{ event.datetime }}</td>
            </tr>
            <tr v-if="event.note">
              <th class="text-left">Note</th>
              <td class="text-right">{{ event.note }}</td>
            </tr>
            <!-- event -->
            <tr v-if="event.title">
              <th class="text-left">Title</th>
              <td class="text-right">{{ event.title }}</td>
            </tr>
            <tr v-if="event.desc">
              <th class="text-left">Description</th>
              <td class="text-right">{{ event.desc }}</td>
            </tr>
            <tr v-if="event.start_time">
              <th class="text-left">Start</th>
              <td class="text-right">{{ event.start_time }}</td>
            </tr>
            <tr v-if="event.end_time">
              <th class="text-left">End</th>
              <td class="text-right">{{ event.end_time }}</td>
            </tr>
            <tr v-if="event.employee">
              <th class="text-left"  rowspan="3">Employee</th>
              <td class="text-right">{{ event.employee.employee_id }}</td>
            </tr>
            <tr v-if="event.employee">
              <td class="text-right">{{ event.employee.name }}</td>
            </tr>
            <tr v-if="event.employee">
              <td class="text-right">{{ event.employee.phone }}</td>
            </tr>
          </table>
        </div>
      </div>
      <template slot="footer" v-if="event">
        <p-button type="success" @click="goto(`${event.url}`)">Edit</p-button>
      </template>
    </modal>
  </div>
  
</template>
<script>
  import swal from 'sweetalert2'
  import { Calendar } from 'fullcalendar'
  import { Modal } from "src/components/UIComponents";
  import { Select, Option, DatePicker, TimeSelect } from "element-ui";

  import { mapGetters } from "vuex";

  const today = new Date();
  const y = today.getFullYear();
  const m = today.getMonth();
  const d = today.getDate();
  export default {
    components: {
      Modal,
      [TimeSelect.name]: TimeSelect,
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    computed: {
      ...mapGetters({
        appointments: "getAppointments",
        employees: "getEmployees",
        events: "getEvents",
      }),
      all_events(){

        let all = [];
        
        if(this.events)
        this.events.forEach(event => {
          let a = {
            start: event.start,
            end: event.end,
            title: event.title,
            className: event.className,
            extendedProps: event
          }
          all.push(a);
        });
        if(this.appointments)
        this.appointments.forEach(event => {
          let a = {
            start: event.start,
            end: event.end,
            title: event.title,
            className: event.className,
            extendedProps: event
          }
          all.push(a);
        });
        
        return all;      
      }
    },
    data() {
      return {
          event: null,
          model: {
            id: null,
            title: null,
            desc: null,
            start_datetime: null,
            end_datetime: null,
            user_id: null,
          },
          modelValidations: {
            title: {
              required: true
            },
            desc: {
              required: false
            },
            start_datetime: {
              required: true
            },
            end_datetime: {
              required: true
            },
            user_id: {
              required: true
            }
          },
          modals: {
            add: false,
            view: false
          },
          scope: 'add',
          calendar: null
      }
    },
    methods: {
      initCalendar() {
          const self = this;
          this.calendar = new Calendar(this.$refs.calendar, {
            header: {
              left: 'title',
              center: 'month,agendaWeek,agendaDay',
              right: 'prev,next,today'
            },
            defaultDate: today,
            selectable: true,
            selectHelper: true,
            views: {
              month: { // name of view
                titleFormat: {
                  month: 'long',
                  year: 'numeric'
                }
                // other view-specific options here
              },
              week: {
                titleFormat: {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric'
                }
              },
              day: {
                titleFormat: {
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric'
                }
              }
            },
            select: async ({ start, end }) => {
              self.openAddModal(start, end)
            },
            editable: false,
            eventLimit: true, // allow "more" link when too many events
            // color classes: [ event-blue | event-azure | event-green | event-orange | event-red ]
            events: self.all_events,
            eventClick: function(info){
              self.openViewModal(info)
            }
          });
          this.calendar.render();
        },
        getError(fieldName) {
        return this.errors.first(fieldName);
      },
      validate() {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.modals.add = false;
            switch(this.scope){
              case 'add'  : this.$store.dispatch("createEvent", this.model); break;
              case 'edit' : this.$store.dispatch("updateEvent", this.model); break;
            }
            setTimeout(() => {
              this.calendar.destroy();
              this.initCalendar();
            }, 1000);
          }
        });      
      },
      openAddModal(start, end){
        this.model.id = null;
        this.model.title = null;
        this.model.desc = null;
        this.model.start_datetime = start;
        this.model.end_datetime = end;
        this.model.user_id = null;
        this.scope = 'add';
        this.modals.add = true;
      },
      openViewModal(info){
        // console.log(info)
        this.event = info.event.extendedProps;
        this.modals.view = true;
      },
      goto(url){
        this.$router.push(url)
      }
    },
    mounted() {
      this.$store.dispatch("fetchEmployeesAll");
      this.$store.dispatch("fetchAppointmentsAll");
      this.$store.dispatch("fetchEventsAll");
      setTimeout(() => {
        this.initCalendar();
      }, 1000);
      
    }
  }
</script>
<style>
  #fullCalendar {
    min-height: 300px;
  }

  .el-loading-spinner .path {
    stroke: #66615B !important;
  }
</style>
